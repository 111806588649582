<template>
  <div class="grid grid-cols-12 gap-6">
    <div class="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6">
      <!-- BEGIN: General Report -->
      <div class="col-span-12 mt-8">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-medium truncate mr-5">
            General Report
          </h2>
        </div>
        <!-- BEGIN: General Report -->
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <i
                    class="report-box__icon text-theme-10"
                    data-feather="shopping-cart"
                  />
                  <div class="ml-auto">
                    <div
                      class="report-box__indicator bg-theme-9 tooltip cursor-pointer"
                      title="33% Higher than last month"
                    >
                      33% <i
                        class="w-4 h-4"
                        data-feather="chevron-up"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  4.510
                </div>
                <div class="text-base text-gray-600 mt-1">
                  Item Sales
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <i
                    class="report-box__icon text-theme-11"
                    data-feather="credit-card"
                  />
                  <div class="ml-auto">
                    <div
                      class="report-box__indicator bg-theme-6 tooltip cursor-pointer"
                      title="2% Lower than last month"
                    >
                      2% <i
                        class="w-4 h-4"
                        data-feather="chevron-down"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  3.521
                </div>
                <div class="text-base text-gray-600 mt-1">
                  New Orders
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <i
                    class="report-box__icon text-theme-12"
                    data-feather="monitor"
                  />
                  <div class="ml-auto">
                    <div
                      class="report-box__indicator bg-theme-9 tooltip cursor-pointer"
                      title="12% Higher than last month"
                    >
                      12% <i
                        class="w-4 h-4"
                        data-feather="chevron-up"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  2.145
                </div>
                <div class="text-base text-gray-600 mt-1">
                  Total Products
                </div>
              </div>
            </div>
          </div>
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <i
                    class="report-box__icon text-theme-9"
                    data-feather="user"
                  />
                  <div class="ml-auto">
                    <div
                      class="report-box__indicator bg-theme-9 tooltip cursor-pointer"
                      title="22% Higher than last month"
                    >
                      22% <i
                        class="w-4 h-4"
                        data-feather="chevron-up"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  152.000
                </div>
                <div class="text-base text-gray-600 mt-1">
                  Unique Visitor
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: General Report -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "@/plugins/swal";
export default {
  name: "DashboardIndex",
  computed: {
    ...mapGetters("profile", {
      currentUser: "currentUser"
    })
  },
  watch: {
    currentUser: {
      handler: function() {
        this.doEmailVerification();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    ...mapActions("profile", {
      emailVerify: "emailVerify"
    }),
    doEmailVerification() {
      if (
        this.$route.name === "email.verify" &&
        this.currentUser.email_verified_at === null
      ) {
        this.emailVerify({
          ...this.$route.params,
          ...this.$route.query
        }).then(() => {
          Swal.fire({
            title: "Success!",
            text: "Your email address has been successfully verified!",
            icon: "success",
            confirmButtonText: "Cool"
          });
        });
      }
    }
  }
};
</script>